import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AutobiographyPage = () => (
  <Layout>
    <SEO title="Autobiography" />
    <h1>Interested in reading more?</h1>
    <a className="mt-10" href="https://urldefense.proofpoint.com/v2/url?u=https-3A__www.amazon.com_dp_B0875XQ2DT_ref-3Dcm-5Fsw-5Fr-5Fem-5Fapi-5Fi-5F0DA8H2NQK2N08Z54KDC1&d=DwIFAg&c=euGZstcaTDllvimEN8b7jXrwqOf-v5A_CdpgnVfiiMM&r=WKx2jxngEirTdkmUarrp-8EkKiC552pzsu-vqxDmPBQ&m=eZu2jL8VZkB35UD_AJnftnFRlxCJKabYgdWC90X-k5I&s=GRAWSLhExxM1sQN4KmvGxa0bt0YsR3z6i4IPetWcFk4&e=">Click Here to buy the Autobiography of Ron Ditch</a>
    <div className="flex flex-row items-end justify-between mt-6 flex-wrap">
    <StaticImage
        src="../images/front_cover.jpg"
        alt="Front cover of book"
        className="max-w-sm h-128"
      />
      <StaticImage
        src="../images/backcover.jpg"
        alt="backcover of book"
        className="max-w-sm h-128"
      />
    </div>
  </Layout>
)

export default AutobiographyPage
